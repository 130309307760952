import {mapGetters, mapMutations, mapActions} from 'vuex';
const _ = require('lodash');

export default {
    data(){
      return{
          category:99999999,
          product:null,
          choiceProduct: null,
          count:1,
          price:0,
          productList:[],
          categoryList:[
              {
                  title:'-ВСЕ-',
                  id:99999999,
              },
          ],
          orderPayload: null,
      }
    },
    computed:{
      ...mapGetters({
          products:'catalog/products',
          order:'orders/order',
          productCategoryList:'catalog/productCategoryList',
          isUpsell:'orders/isUpsell'
      })
    },
    watch:{
        products(e) {
            e.data.map(item =>{
                this.productList.push(item)
            })
        },
        productCategoryList(e){
            e.map(item => {
                this.categoryList.push(item)
            })
        }

    },
    created() {
        this.orderPayload = _.clone(this.order);
        this.getProducts();
        this.getProductCategories();
    },
    methods:{
        addProductToOrder(){
            let productChoice = this.productList.filter(item => item.id === this.product)
            productChoice = productChoice[0].title;
            const addedProduct = {
                "product_id": this.product,
                "price": this.price,
                "quantity": this.count,
                "is_upsell": this.isUpsell,
                "title": productChoice,
            }
            this.orderPayload = {
                products: []
            }
            this.$emit('addProduct', addedProduct )
            this.toggleShowAddProductPopUp(false)
            // this.orderPayload.products.push(addedProduct)
            // const payload = {
            //     ip: this.orderPayload.ip,
            //     products: [addedProduct],
            //     customer_name: this.orderPayload.customer_name,
            //     order_id: this.orderPayload.order_id,
            //     phone: this.orderPayload.phone,
            //     site: this.orderPayload.site,
            // }
            // this.editOrder({
            //     id: this.orderPayload.id,
            //     data: payload
            // }).then(res=>{
            //     if(res.status){
            //         this.showOrder(this.orderPayload.id)
            //         this.toggleShowAddProductPopUp(false)
            //     }
            // })
        },
        getCategoryForProducts(){
            this.productList = [];
            if(this.category === 99999999){
                this.products.data.map(item =>{
                    this.productList.push(item)
                })
            }else{
                this.products.data.map(item =>{
                    if(item.id === this.category){
                        this.productList.push(item)
                    }
                })
            }
        },
        getProduct(){
            this.choiceProduct = this.products.data.filter(item=>item.id === this.product)
            this.choiceProduct = this.choiceProduct[0];
            if(this.choiceProduct){
                this.price = Number(this.choiceProduct.sale_price).toFixed(2)
            }
        },
        ...mapMutations({
            toggleShowAddProductPopUp: 'config/toggleShowAddProductPopUp',
        }),
        ...mapActions({
            getProducts: 'catalog/getProducts',
            getProductCategories: 'catalog/getProductCategories',
            editOrder:'orders/editOrder',
            showOrder:'orders/showOrder',
        })
    }
}